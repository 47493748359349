import { View, Button, VStack, useTheme, Box, HStack, ScrollView } from 'native-base';

import { ContentContainer } from 'src/components/container/ContentContainer';
import { SubHeader } from 'src/components/typography/SubHeader';
import { AddPassengerButton } from './components/AddPassengerButton';
import { PassengerItem } from './components/PassengerListItem';
import { useLinkProps } from '@react-navigation/native';
import {
  CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME,
  PAYMENT_CONFIRMATION_SCREEN_NAME,
  SELECT_SEAT_NAME,
} from 'src/navigation/constants';
import { CreatePassengerInput } from 'src/graphql/ticketing_api/mutations/create-reservation';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { ReservationHeader } from './components/ReservationHeader';
import { isWeb } from 'src/utils/environment.utils';
import { Input } from 'src/components/input/Input';
import { THEME_INPUT_HEIGHT } from 'src/providers/design-provider/constants';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useState } from 'react';

export const PassengertInformation: React.FC = () => {
  const { passengers, search, setEditablePassengerIndex, departureSchedule, promoCode, setPromoCode } = useReservation();

  const [tempPromoCode, setTempPromoCode] = useState<string>();
  const { onPress: goToSelectSeat } = useLinkProps({ to: { screen: SELECT_SEAT_NAME } });
  const { onPress: goToPaymentConfirmation } = useLinkProps({ to: { screen: PAYMENT_CONFIRMATION_SCREEN_NAME } });
  const { onPress: goToCreateOrEditPassengerInfo } = useLinkProps({
    to: { screen: CREAT_OR_EDIT_PASSENGER_INFO_SCREEN_NAME },
  });
  const { colors } = useTheme();

  const onAddNewPassenger = () => {
    goToCreateOrEditPassengerInfo();
  };

  const passengerCount = (search?.adult ?? 0) + (search?.child ?? 0) + (search?.infant ?? 0);
  const isDisabled = passengerCount > (passengers?.length ?? 0);
  const isCompleted = passengerCount === passengers?.length;

  const isValidPromoCode = (input: string) => {
    const regex = /^[a-zA-Z0-9]+$/;
    return regex.test(input);
  };

  const isApplyPromoDisabled = (tempPromoCode && tempPromoCode === promoCode) || !tempPromoCode || !isValidPromoCode(tempPromoCode);

  const onEditPassenger = (_: CreatePassengerInput, index: number) => {
    setEditablePassengerIndex(index);
    goToCreateOrEditPassengerInfo();
  };

  return (
    <ContentContainer
      allWhiteBackground={isWeb() ? false : true}
      showFooter={false}
      header={<ReservationHeader />}
      headerFullWidth={true}
    >
      <ScrollView>
        <View mt={4}>
          <SubHeader text={`${passengers?.length}/${passengerCount} Entries`} />
          <View marginTop={3}>
            <VStack space={1}>
              {passengers?.map((p, i) => (
                <PassengerItem key={`${i}`} index={i} passenger={p} onPassengerEdited={onEditPassenger} />
              ))}
            </VStack>
          </View>
          {!isCompleted && <AddPassengerButton onPress={onAddNewPassenger} />}
        </View>

        <Box
          shadow={1}
          marginBottom={10}
          borderWidth={1}
          borderColor={colors.gray[100]}
          padding={3}
          mt={10}
          backgroundColor={colors.white}
        >
          <SubSubHeader text="Optional promo code" mb={2} />
          <HStack space={2}>
            <Input
              flex={3}
              placeholder="Optional promo code"
              onChangeText={setTempPromoCode}
              errorText={tempPromoCode && !isValidPromoCode(tempPromoCode) ? '' : undefined}
            />
            <Button
              flex={1}
              // TODO: We should probably validate the promocode.
              onPress={() => tempPromoCode && setPromoCode(tempPromoCode)}
              isDisabled={isApplyPromoDisabled}
              height={THEME_INPUT_HEIGHT}
              disabled={isApplyPromoDisabled}
            >
              Apply Code
            </Button>
          </HStack>
        </Box>
        {isCompleted && (
          <Button
            style={{ marginTop: 100 }}
            onPress={departureSchedule?.trip.isCheckinAvailable ? goToSelectSeat : goToPaymentConfirmation}
            isDisabled={isDisabled}
          >
            Proceed
          </Button>
        )}
      </ScrollView>
    </ContentContainer>
  );
};
