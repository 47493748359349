import { Button, ScrollView, VStack, Text, HStack, Checkbox } from 'native-base';
import { FC, useEffect, useRef, useState } from 'react';
import { SingleValue, MultiValue } from 'react-select';

import { Input } from 'src/components/input/Input';
import { NewSelect } from 'src/components/select/NewSelect';
import { SearchableSelect } from 'src/components/select/SearchableSelect';
import { SelectItem } from 'src/components/select/Select';
import { CreatePassengerInput } from 'src/graphql/ticketing_api/mutations/create-reservation';
import { PassengerTypeEnum, genderOptions, titleOptions } from 'src/screens/book-seat/ReservationUtils';
import { isWeb } from 'src/utils/environment.utils';
import { PhoneInput, PhoneNumberInput } from 'src/components/phone-number-input/PhoneNumberInput';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const phoneRegex = /^\d{11}$/;
const containsAlphabetRegex = /[a-zA-Z]/;

export interface PassengerFormProps {
  type?: PassengerTypeEnum;
  onComplete?: () => void;
  addPassenger?: (passengers: CreatePassengerInput) => void;
  editablePassenger?: CreatePassengerInput;
  editablePassengerIndex?: number;
  updatePassenger?: (id: number, passengers: CreatePassengerInput) => void;
  isVisible: boolean;
}

export const PassengerInformationForm: FC<PassengerFormProps> = ({
  type,
  onComplete,
  addPassenger,
  editablePassenger,
  updatePassenger,
  isVisible,
  editablePassengerIndex,
}) => {
  const [passenger, setPassenger] = useState<Partial<CreatePassengerInput> | undefined>();
  const { passengers: BookingPassengers } = useReservation();
  const primaryPassenger = BookingPassengers?.find(passenger => passenger.isPrimaryPassenger);
  const [passengerIndex, setPassengerIndex] = useState<Partial<number>>();
  const [sameAsPrimary, setSameAsPrimary] = useState<boolean>(false);
  const phoneInput = useRef<PhoneInput>(null);

  const fullName = (passenger?.fullName ?? '').trim().split(' ');

  useEffect(() => {
    setPassenger(editablePassenger);
  }, [editablePassenger]);

  useEffect(() => {
    setPassengerIndex(editablePassengerIndex);
  }, [editablePassengerIndex]);

  useEffect(() => {
    if (!isVisible) {
      setPassenger(undefined);
    }
  }, [isVisible]);

  const onChange = (key: keyof CreatePassengerInput, value: string) => {
    const updatedPassenger = { ...passenger, [key]: value };
    setPassenger(updatedPassenger);
  };

  const onSetSameNextOfKin = (checked: boolean) => {
    setSameAsPrimary(checked);
  };

  const onSubmit = () => {
    const p = {
      ...passenger,
      nextKinFirstName: sameAsPrimary ? primaryPassenger?.nextKinFirstName : passenger?.nextKinFirstName,
      nextKinLastName: sameAsPrimary ? primaryPassenger?.nextKinLastName : passenger?.nextKinLastName,
      nextKinPhone: sameAsPrimary ? primaryPassenger?.nextKinPhone : passenger?.nextKinPhone,
    } as CreatePassengerInput;

    if (addPassenger && !editablePassenger) {
      p.type = type ?? '';
      addPassenger(p);
    } else if (passenger && updatePassenger && editablePassenger && passengerIndex !== undefined) {
      updatePassenger(passengerIndex, p);
    }

    if (onComplete) {
      onComplete();
    }
  };

  const isComplete =
    passenger?.email &&
    passenger?.fullName &&
    passenger?.gender &&
    passenger?.phone &&
    passenger?.title &&
    emailRegex.test(passenger?.email) &&
    fullName.length > 1 &&
    phoneRegex.test(passenger?.phone) &&
    !containsAlphabetRegex.test(passenger?.phone) &&
    (sameAsPrimary ||
      (passenger?.nextKinFirstName &&
        passenger?.nextKinLastName &&
        passenger?.nextKinPhone &&
        phoneRegex.test(passenger?.nextKinPhone)
      )
    );
  const isMobile = !isWeb();

  return (
    <ScrollView width={{ base: '100%', md: '95%', lg: '50%' }} showsVerticalScrollIndicator={false} automaticallyAdjustKeyboardInsets>
      <VStack justifyContent={'center'} p={2} space={2}>
        {isMobile ? (
          <NewSelect
            label="Title"
            placeholder="Select title"
            isRequired
            items={titleOptions}
            defaultValue={passenger?.title}
            onValueChange={selectedValue => onChange('title', selectedValue)}
          />
        ) : (
          <SearchableSelect
            label="Title"
            formControlStyle={{ width: { base: 'full' }, isRequired: true, zIndex: 2 }}
            placeholder="Select title"
            isRequired
            items={titleOptions}
            isMulti={false}
            value={titleOptions.find(option => option.value === passenger?.title) || null}
            onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) =>
              onChange('title', (newValue as SingleValue<SelectItem>)?.value ?? '')
            }
          />
        )}
        <Input
          defaultValue={passenger?.fullName}
          width="100%"
          label="Full Name"
          isRequired
          infoText="Enter surname last"
          errorText={passenger?.fullName && fullName.length < 2 ? 'Please enter at least a first name and a last name.' : undefined}
          placeholder="Enter full name"
          onChangeText={v => onChange('fullName', v)}
        />
        {isWeb() ? (
          <HStack width="100%" alignItems="center">
            <Input
              label="Phone Number"
              isRequired
              width="100%"
              defaultValue={passenger?.phone ?? ''}
              placeholder="Enter 11 digits"
              maxLength={11}
              keyboardType="number-pad"
              onChangeText={v => {
                onChange('phone', v);
              }}
              errorText={
                passenger?.phone && passenger?.phone.length !== 0
                  ? containsAlphabetRegex.test(passenger?.phone)
                    ? 'Phone number cannot contain alphabetic characters.'
                    : !phoneRegex.test(passenger?.phone)
                    ? 'Phone number must contain exactly 11 digits.'
                    : undefined
                  : undefined
              }
              paddingLeft={0}
            />
          </HStack>
        ) : (
          <PhoneNumberInput
            ref={phoneInput}
            label="Phone Number"
            isRequired
            placeholder="Enter phone number"
            defaultValue={passenger?.phone ?? ''}
            defaultCode="NG"
            layout="first"
            onChangeText={text => onChange('phone', text)}
          />
        )}
        <Input
          defaultValue={passenger?.email ?? ''}
          width="100%"
          label="Email Address"
          placeholder="email@domain.com"
          onChangeText={v => onChange('email', v)}
          errorText={
            passenger?.email && passenger?.email?.length !== 0 && !emailRegex.test(passenger?.email)
              ? 'enter a valid email'
              : undefined
          }
        />
        {isMobile ? (
          <NewSelect
            label="Gender"
            placeholder="Select gender"
            headerTitle="Sekect gender"
            isRequired
            items={genderOptions}
            defaultValue={passenger?.gender}
            onValueChange={selectedValue => onChange('gender', selectedValue)}
          />
        ) : (
          <SearchableSelect
            label="Gender"
            formControlStyle={{ width: { base: 'full' }, isRequired: true, zIndex: 2 }}
            placeholder="Select gender"
            isRequired
            items={genderOptions}
            isMulti={false}
            value={genderOptions.find(option => option.value === passenger?.gender) || null}
            onChange={(newValue: SingleValue<SelectItem> | MultiValue<SelectItem>) =>
              onChange('gender', (newValue as SingleValue<SelectItem>)?.value ?? '')
            }
          />
        )}
        {BookingPassengers && BookingPassengers.length > 0 && !passenger?.isPrimaryPassenger && (
          <Checkbox marginTop={3} value="sameAsPrimary" isChecked={sameAsPrimary} onChange={onSetSameNextOfKin}>
            <Text fontSize="sm" color="gray.800" fontFamily="body">
              Use the Next of Kin details of the primary passenger
            </Text>
          </Checkbox>
        )}

        {((BookingPassengers && !BookingPassengers.length) || !sameAsPrimary || passenger?.isPrimaryPassenger) && (
          <>
            <Input
              defaultValue={passenger?.nextKinFirstName ?? ''}
              width="100%"
              label="Next of Kin First Name"
              isRequired
              placeholder="Enter next of kin first name"
              onChangeText={v => onChange('nextKinFirstName', v)}
            />
            <Input
              defaultValue={passenger?.nextKinLastName ?? ''}
              width="100%"
              label="Next of Kin Last Name"
              isRequired
              placeholder="Enter next of kin last name"
              onChangeText={v => onChange('nextKinLastName', v)}
            />
            <Input
              defaultValue={passenger?.nextKinPhone ?? ''}
              width="100%"
              label="Next of Kin Phone Number"
              isRequired
              placeholder="Enter phone number"
              maxLength={11}
              keyboardType="number-pad"
              onChangeText={v => onChange('nextKinPhone', v)}
              errorText={
                passenger?.nextKinPhone && !phoneRegex.test(passenger?.nextKinPhone)
                  ? 'Phone number must contain exactly 11 digits.'
                  : undefined
              }
            />
          </>
        )}
        <Button size="lg" mt={18} onPress={onSubmit} isDisabled={!isComplete}>
          {editablePassenger ? 'Update' : 'Add'}
        </Button>
      </VStack>
    </ScrollView>
  );
};
