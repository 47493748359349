import { FC, useEffect, useState } from 'react';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { ReservationHeader } from './components/ReservationHeader';
import { SelectSeatPassengerTab } from './components/SelectSeatPassengerTab';
import { Box, Button, Center, ScrollView, Spinner, View } from 'native-base';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { TripSeatMapQuery_tripSeatMap_Seat, useGetTripSeatMapQuery } from 'src/graphql/ticketing_api/queries/get-trip-seatmap';
import { getSeatMapGrid } from 'src/screens/book-seat/ReservationUtils';
import { SeatSelector } from './components/SeatSelector';
import { BodyText } from 'src/components/typography/BodyText';
import { capitalizeWord } from 'src/utils/string';
import { CreatePassengersInput } from 'src/graphql/generated/types';
import { usePriceBookingnMutation } from 'src/graphql/ticketing_api/mutations/price-booking';
import { useLinkProps } from '@react-navigation/native';
import { PAYMENT_CONFIRMATION_SCREEN_NAME } from 'src/navigation/constants';
import { captureException } from 'src/utils/sentry';

export const SelectSeat: FC = () => {
  const { passengers, departureSchedule, updatePassenger, setBookingPrice, promoCode } = useReservation();
  const [priceBooking, { loading: priceBookingLoading, error: priceBookingError }] = usePriceBookingnMutation();
  const { data, loading, error } = useGetTripSeatMapQuery(departureSchedule?.tripId.toString() ?? '');
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { onPress: goToPaymentConfirmation } = useLinkProps({ to: { screen: PAYMENT_CONFIRMATION_SCREEN_NAME } });

  useEffect(() => {
    if (priceBookingError) {
      captureException({ message: 'Error getting price booking', priceBookingError });
      console.log({ message: 'Error getting price booking', priceBookingError: priceBookingError.networkError?.stack });
    }
  }, [priceBookingError]);

  if (!data || !passengers || loading || error) return <Spinner />;

  const onSeatSelected = (seat: TripSeatMapQuery_tripSeatMap_Seat) => {
    const passenger = passengers[activeIndex];
    const updatedPassngerWithSeat: CreatePassengersInput = { ...passenger, seatId: parseInt(seat.id, 10) };
    updatePassenger(activeIndex, updatedPassngerWithSeat);
    if (activeIndex + 1 < passengers.length) setActiveIndex(activeIndex + 1);
  };

  const getBookingPrice = async () => {
    const bookingPrice = await priceBooking({
      variables: {
        input: {
          promoCode,
          departure: passengers,
        },
      },
    });

    if (bookingPrice.data) {
      setBookingPrice(bookingPrice.data?.priceBooking);
      goToPaymentConfirmation();
    }
  };

  const grid = getSeatMapGrid(data);
  const selectedSeatIds = passengers.filter(p => !!p.seatId).map(p => p.seatId) as number[];
  const canPriceBooking = selectedSeatIds.length === passengers.length;
  const buttonDisabled = !canPriceBooking || priceBookingLoading;

  return (
    <ContentContainer allWhiteBackground={false} showFooter={false} header={<ReservationHeader />} headerFullWidth={true}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View>
          <SelectSeatPassengerTab
            seats={data.tripSeatMap.seats}
            passengers={passengers}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        </View>
        <Center>
          <BodyText text={capitalizeWord(departureSchedule?.trip.vehicleClass.name ?? '')} bold mt={5} />
        </Center>
        <Box backgroundColor={'white'} padding={10} marginTop={5}>
          <SeatSelector seatMap={grid} onSeatSelected={onSeatSelected} selectedSeatIds={selectedSeatIds} />
        </Box>
        <Button marginTop={10} isDisabled={buttonDisabled} disabled={buttonDisabled} onPress={getBookingPrice}>
          Continue
        </Button>
      </ScrollView>
    </ContentContainer>
  );
};
