import { Box, Button, Divider, Flex, View, useTheme, Actionsheet } from 'native-base';
import React, { useEffect, useState } from 'react';
import { ContentContainer } from 'src/components/container/ContentContainer';
import { BodyText } from 'src/components/typography/BodyText';
import { SmallLabel } from 'src/components/typography/SmallLabel';
import { BasketItem, PassengerTypeEnum } from './ReservationUtils';
import { SubSubHeader } from 'src/components/typography/SubSubHeader';
import { useReservation } from 'src/providers/reservation-provider/ReservationProvider';
import { getCurrencyText } from 'src/utils/currency';
import { useCreateReservationMutation } from 'src/graphql/ticketing_api/mutations/create-reservation';
import { ReservationPayment } from './components/ReservationPayment.web';
import { useCompleteBookingnMutation } from 'src/graphql/ticketing_api/mutations/complete-booking';
import { LANDING_SCREEN_NAME, RESERVATION_CONFIRMED_SCREEN_NAME } from 'src/navigation/constants';
import { useLinkProps } from '@react-navigation/native';
import { ReservationHeader } from 'src/screens/book-seat/components/ReservationHeader';
import { LoadingState } from 'src/components/loading-state/LoadingState';
import { usePayment } from 'src/hooks/usePayment';
import { SubHeader } from 'src/components/typography/SubHeader';
import { capitalize } from 'src/utils/string';
import { BookingNote } from 'src/screens/book-seat/components/BookingNote';
import moment from 'moment';

export const PaymentConfirmation: React.FC = () => {
  const { bookingPrice, passengers, setReservation, reservation, setCompleteBooking, promoCode } = useReservation();
  const { processPayment } = usePayment(reservation);
  const [completeBooking, { loading: completeBookingLoading, error: completeBookingError }] = useCompleteBookingnMutation();
  const [createReservation, { loading: createReservationLoading }] = useCreateReservationMutation();
  const [showPaymentProviders, setShowPaymentProviders] = useState<boolean>();
  const { onPress: goToReservationSuccess } = useLinkProps({
    to: { screen: RESERVATION_CONFIRMED_SCREEN_NAME },
  });

  const { onPress: goToHome } = useLinkProps({
    to: { screen: LANDING_SCREEN_NAME },
  });
  const { colors } = useTheme();

  const expiryDate = reservation?.createOrEditReservation?.reservation?.onHoldTill
    ? moment(reservation?.createOrEditReservation?.reservation?.onHoldTill).subtract(5, 'minutes')
    : null;
  const [isExpired, setIsExpired] = useState<boolean>(false);

  useEffect(() => {
    if (!expiryDate) return;
    const interval = setInterval(() => {
      if (moment().isAfter(expiryDate)) {
        setIsExpired(true);
        clearInterval(interval);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  useEffect(() => {
    if (isExpired) {
      goToHome();
    }
  }, [isExpired]);

  // for now we are just interested in one way departure trips
  const departure = bookingPrice?.departure;

  const adultCount = departure?.passengers.filter(p => p.type === PassengerTypeEnum.Adult).length ?? 0;
  const childCount = departure?.passengers.filter(p => p.type === PassengerTypeEnum.Child).length ?? 0;
  const infantCount = departure?.passengers.filter(p => p.type === PassengerTypeEnum.Infant).length ?? 0;

  const isLoading = createReservationLoading || completeBookingLoading;

  const createReservationAndProcessPayment = async (provider: string) => {
    if (passengers?.length) {
      let res = reservation;
      if (!res) {
        const newReservation = await createReservation({
          variables: {
            input: {
              promoCode,
              passengers,
            },
            provider,
          },
        });
        res = newReservation.data ?? undefined;
      }
      setShowPaymentProviders(false);
      if (res) {
        setReservation(res);
      }
    }
  };

  const onPaymentButtonCLicked = () => {
    if (!reservation) {
      setShowPaymentProviders(true);
    } else if (completeBookingError) {
      completeReservation();
    } else {
      processPayment(completeReservation);
    }
  };

  const completeReservation = async () => {
    if (!reservation) return;
    const complete = await completeBooking({
      variables: { reservationId: parseInt(reservation.createOrEditReservation.reservation.id, 10) },
    });
    if (complete.data?.completeBooking) {
      setCompleteBooking(complete.data?.completeBooking);
      setShowPaymentProviders(false);
      goToReservationSuccess();
    }
  };

  return (
    <ContentContainer allWhiteBackground={false} showFooter={false} header={<ReservationHeader />} headerFullWidth={true}>
      <Box shadow={2} marginBottom={10} borderWidth={1} borderColor={colors.gray[100]} mt={4} backgroundColor={colors.white}>
        <View padding={5}>
          <Flex direction="row" justifyContent="space-between">
            <View flex={1}>
              <SmallLabel text="Departure" />
              <BodyText text={departure?.schedule.branchName ?? ''} />
            </View>
            <View flex={1} textAlign={'right'}>
              <SmallLabel text="Arrival" />
              <BodyText text={departure?.schedule.destinationName ?? ''} />
            </View>
          </Flex>

          <Divider marginTop={5} marginBottom={5} />

          <BasketItem label="No of Adults" value={String(adultCount)} />
          <BasketItem label="No of Children" value={String(childCount)} />
          <BasketItem label="No of Infants" value={String(infantCount)} />

          <Divider marginTop={5} marginBottom={5} />

          <BasketItem label="Departure Date" value={departure?.schedule.date ?? ''} />
          <BasketItem label="Departure Time" value={departure?.schedule.time ?? ''} />
          <BasketItem label="Vehicle No" value={departure?.schedule.vehicleNo ?? ''} />
          <BasketItem label="Vehicle Class" value={departure?.schedule.vehicleClass ?? ''} />
          <BasketItem
            label="Discount"
            value={
              departure?.actualFare && departure?.totalFare
                ? getCurrencyText(departure.actualFare - departure?.totalFare)
                : getCurrencyText(0)
            }
          />

          <Divider marginTop={5} marginBottom={5} />

          <BasketItem label="TOTAL FARE" value={getCurrencyText(departure?.actualFare ?? 0)} />
        </View>
        <Flex background="#FAFAFA" direction="row" alignItems="center" justifyContent={'right'} pt={3} pb={3} pr={5} pl={5}>
          <SmallLabel text="Amount Payable" marginRight={2} />
          <SubSubHeader text={getCurrencyText(departure?.totalFare ?? 0)} bold />
        </Flex>
      </Box>
      {reservation?.createOrEditReservation && <BookingNote expiryDate={reservation.createOrEditReservation.reservation.onHoldTill} />}
      <Button disabled={isLoading} isDisabled={isLoading} isLoading={isLoading} onPress={onPaymentButtonCLicked}>
        {reservation ? `Pay with ${capitalize(reservation.createOrEditReservation.transaction.provider ?? '')}` : 'Proceed to Payment'}
      </Button>
      <Actionsheet isOpen={showPaymentProviders} onClose={() => setShowPaymentProviders(false)}>
        <Actionsheet.Content alignItems={'flex-start'}>
          <SubHeader paddingLeft={3} paddingBottom={1} text="Pay with" bold />
          <Divider />
          {isLoading && (
            <Flex justifyContent="center" alignItems="center">
              <LoadingState />
            </Flex>
          )}
          {!isLoading && <ReservationPayment onProviderSelected={createReservationAndProcessPayment} />}
        </Actionsheet.Content>
      </Actionsheet>
    </ContentContainer>
  );
};
