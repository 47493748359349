import Ionicons from '@expo/vector-icons/Ionicons';
import { FormControl, HStack, IInputProps, Input as NBInput, useTheme } from 'native-base';
import { BodyText } from '../typography/BodyText';

export interface InputProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  infoText?: string;
  errorText?: string;
  successText?: string;
  isRequired?: boolean;
}

export const Input = ({
  label,
  isRequired,
  mt,
  helperText,
  infoText,
  errorText,
  successText,
  flex,
  ...props
}: InputProps & IInputProps) => {
  const { colors } = useTheme();
  return (
    <FormControl isRequired={isRequired} mt={mt} width={props.width} flex={flex}>
      {label && <FormControl.Label>{label}</FormControl.Label>}
      <NBInput accessibilityLabel={label} {...props} />
      {helperText && <FormControl.HelperText>{helperText}</FormControl.HelperText>}
      {(errorText || infoText || successText) && (
        <HStack space={2} alignItems={'center'} color={colors.info[300]}>
          <Ionicons
            color={errorText ? colors.danger[100] : infoText ? colors.info[500] : colors.success[300]}
            name="information-circle-sharp"
          />
          <BodyText text={errorText ?? infoText ?? successText ?? ''} color={colors.gray[400]} fontSize={12} />
        </HStack>
      )}
    </FormControl>
  );
};
